import request from '@/utils/request'

// 课程列表
export function getStudy(data) {
  return request({
    url: 'api/v2/m_xt/getStudy',
    method: 'post',
    data
  })
}

//新增课程
export function saveStudy(data) {
  return request({
    url: 'api/v2/m_xt/saveStudy',
    method: 'post',
    data
  })
}

//删除课程
export function deleteStudy(data) {
  return request({
    url: 'api/v2/m_xt/deleteStudy',
    method: 'post',
    data
  })
}